import { chatConfig as ChatConfig, chatWindow as ChatWindow } from 'kore-web-sdk';

/**
 * Global functions, available on the JavaScript window object.
 */
declare global {
    interface Window {
        elmoAiAssistantSetup: Function,
        elmoAiAssistant: Function,
    }
}

/**
 * Options that can be supplied to the elmoAiAssistantSetup function.
 * menu_url is optional, and won't be used if bot.jwt is supplied directly.
 */
interface ElmoAiAssistantOptions {
    menu_url: URL|string|null,
    bot: BotOptions,
}

/**
 * Kore AI internal BotOptions
 * Will change with KoreAI Library
 */
interface BotOptions {
    assertion: string,
    jwt: string,
    userIdentity: string,
    searchIndexID: string,
    clientId: string
    botInfo: {
        chatBot: string,
        taskBotId: string,
    }
}

/**
 * Response given by ELMO Kore AI Integration API
 * Contains the Credentials and Settings for the Bot.
 */
interface ElmoKoreAiResponseSettings {
    jwt: string,
    userIdentity: string,
    searchIndexID: string,
    clientId: string
    botInfo: {
        chatBot: string,
        taskBotId: string,
    }
}

/**
 * Initializes the Bot popup window within the page.
 */
class ElmoAssist {
    setup(_options: ElmoAiAssistantOptions) {

        const chatConfig = ChatConfig;

        const chatWindowInstance = new ChatWindow(chatConfig);
        const events = new EventTarget();

        // Internal state
        const state = {
            ready: false,
            show: false,
        };

        // Merge _options onto these defaults
        const options: ElmoAiAssistantOptions = {
            ...{
                menu_url: null,
                bot: {
                    jwt: null,
                    user: null,
                    botInfo: {},
                },
            },
            ..._options
        };

        /**
         * Menu entrypoint: The menu will call this function.
         */
        window.elmoAiAssistant = () => {
            state.show = !state.show;
            events.dispatchEvent(new Event('show.toggle'));
        }

        /**
         * Event: "show.toggle"
         * Call this to toggle visibility of the chat window.
         */
        events.addEventListener('show.toggle', (event) => {
            if (!state.ready) return;

            if (window?.document?.querySelector(".kore-chat-window.active")) {
                // Window is open? Use the Close button
                document.querySelector(".kore-chat-window-main-section .btn-action-close")?.click();
            } else {
                // Window is closed? Click the (invisible) text box
                document.querySelector(".kore-chat-window-main-section .avatar-bg")?.click();;
            }
        });

        /**
         * Call the API to load the Bot settings, or use existing settings.
         * Settings will only be loaded once.
         */
        const loadSettings = () => {
            return new Promise<any>((resolve, reject) => {
                // Use the Bot options if they're already set
                if (options?.bot?.jwt?.length > 0) {
                    resolve(options.bot);
                    return;
                }

                fetch(options.menu_url as URL)
                    .then(async (response: any) => {
                        if (!response.ok) {
                            return reject("Invalid API response");
                        }

                        const botData = (await response.json()).assistant as ElmoKoreAiResponseSettings;
                        options.bot = {...options.bot, ...botData}; // Merge API payload
                        resolve(options.bot);
                    })
                    .catch((err) => {
                        reject(err)
                    });
            });
        }

        /**
         * Initialize the Bot after loading settings from the API
         * The "options" variable must be populated
         */
        const loadBot = () => {

            // Monkey-patch JWT retrieval for backend fetch
            chatWindowInstance.getJWT = function (botOptions: BotOptions, callback: Function) {
                return new Promise((resolve, reject) => {
                    loadSettings()
                        .then((bot: ElmoKoreAiResponseSettings) => {
                            if (!bot.jwt || !bot.clientId) {
                                console.error("Invalid payload from configuration API");
                            }

                            // Configure bot from API
                            botOptions.jwt = bot.jwt;
                            botOptions.assertion = bot.jwt;
                            botOptions.botInfo = bot.botInfo;
                            botOptions.clientId = bot.clientId;
                            botOptions.userIdentity = bot.userIdentity;

                            // Impart ready state
                            state.ready = true;

                            // Callback, if provided
                            callback && callback(null, botOptions);

                            // Resolve JWT
                            resolve({
                                jwt: bot.jwt,
                            });
                        })
                        .catch((e) => {
                            console.error("Failed to load ELMO AI Assist Settings", e);
                        });
                });
            };

            // Hack on the Kore AI Styles to suite our needs.
            // This is highly brittle CSS wrangling, likely to break between versions! Comments indicate intent.
            // These styles are overly-specific in order to win a CSS specificity-arms-race with the base KoreAI styles.
            const style = document.createElement('style');

             style.textContent
                 // Fix z-index to raise above other z-indexed elements, but below Pendo
                 = '.kore-chat-window-main-section { z-index: 18000; }'
                 // Hide the chat window when minimised. Only show the main chat window.
                 + '.kore-chat-window-main-section.minimize-chat { display: none !important; }'
                 // Hide the Minimize avatar which can close the chat window
                 + '.kore-chat-window-main-section .avatar-minimize { display: none !important; }'
                 // Hide "powered by koreai" footers
                 + '.welcome-chat-section footer, .powerdby-info { display: none !important; }'
                 // Remove span-based hover behavior for answer snippets
                 + '.sa-answer-block .sa-answer-result-block .sa-answer-result-sub-block:hover .sa-answer-result-heading { color: inherit; }'
             ;
            document.head.append(style);

            // Reveal!
            chatWindowInstance.show(chatConfig);
        }

        /**
         * Run the Bot
         */
        loadSettings().then(loadBot);
    }
}

export const elmoAssist = new ElmoAssist;
